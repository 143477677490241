import { Box, SxProps, Typography } from '@mui/material';
import { ImageLoader, loadImage } from '@prismamedia/one-components';
import { CircularLoader } from 'components/Loader/Circular';
import { Options } from 'pages/RailroadPage/DisplayOptions';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist';
import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { getRailroadImage } from '../utils';
import { RailroadStatusBar } from './RailroadStatusBar';
import { getColorFromDecimal, getWorkFlow, HalfPage } from './utils';

// eslint-disable-next-line immutable/no-mutation
GlobalWorkerOptions.workerSrc = '/pdf.worker.min.mjs';

export const RailroadIssueHalfPage: FC<{
  page: HalfPage | null;
  previousPage: HalfPage | null;
  nextPage: HalfPage | null;
  displayOptions?: Options;
  sx?: SxProps;
  pageWidth: number;
  pageRatio: number | null;
  setPageRatio: (height: number | null) => void;
  selected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
}> = ({
  page,
  previousPage,
  nextPage,
  sx,
  pageWidth,
  setPageRatio,
  displayOptions,
  pageRatio,
  selected,
  onClick,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const isPdf = page?.filename?.endsWith('.pdf');

  useEffect(() => {
    const loadImageData = async () => {
      setImageSrc(null);
      setLoading(false);

      if (!page?.filename) {
        return;
      }

      const res = await getRailroadImage(page.filename);

      if (isPdf) {
        setLoading(true);
        const pdfData = await res.arrayBuffer();
        const pdf = await getDocument({ data: pdfData }).promise;
        const pdfPage = await pdf.getPage(1);
        const viewport = pdfPage.getViewport({ scale: 1 });
        const canvas = canvasRef.current;
        if (!canvas) return;
        const context = canvas.getContext('2d');
        if (!context) return;
        // eslint-disable-next-line immutable/no-mutation
        canvas.width = viewport.width;
        // eslint-disable-next-line immutable/no-mutation
        canvas.height = viewport.height;

        const renderContext = { canvasContext: context, viewport };
        await pdfPage.render(renderContext).promise;
        setLoading(false);

        return;
      }

      const src = URL.createObjectURL(res);
      setImageSrc(src);
      const image = await loadImage(src);
      setPageRatio?.(image.height / image.width);
    };

    loadImageData().catch((e) => {
      console.error(e);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page?.filename]);

  const pageIsEven = Number(page?.pageNumber || 0) % 2 === 0;

  const workflow = getWorkFlow(page);

  if (!page) return null;

  return (
    <Box
      sx={{
        breakInside: 'avoid',
        width: pageWidth,
        ...sx,
      }}
    >
      {displayOptions?.displayInfos && (
        <>
          <RailroadStatusBar
            value={page.sheet?.id}
            previousValue={previousPage?.sheet?.id}
            nextValue={nextPage?.sheet?.id}
            pageNumber={page.pageNumber}
            pageWidth={pageWidth}
            label={page.sheet?.name}
            sx={{ mb: 0.5 }}
            color={getColorFromDecimal(page.sheet?.color)}
          />
          <RailroadStatusBar
            value={page.indesignFilename}
            previousValue={previousPage?.indesignFilename}
            nextValue={nextPage?.indesignFilename}
            pageNumber={page.pageNumber}
            pageWidth={pageWidth}
            label={page.indesignFilename}
            sx={{ mb: 1 }}
            color={page.indesignFilename ? theme.palette.grey[400] : undefined}
          />
          <Typography
            sx={{
              width: '100%',
              textAlign: pageIsEven ? 'left' : 'right',
              fontSize: 10,
              height: 15,
              maxWidth: pageWidth,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '@media print': {
                fontSize: 4,
                height: 6,
              },
            }}
          >
            {workflow?.label_workflowStep}
          </Typography>
        </>
      )}
      <Box
        onClick={onClick}
        sx={{
          border: selected
            ? `${pageWidth * 0.04}px solid ${theme.palette.primary.main}`
            : !displayOptions || displayOptions.displayBorders
            ? `${pageWidth * 0.02}px solid ${
                displayOptions?.miniature
                  ? theme.palette.grey[200]
                  : getColorFromDecimal(Number(workflow?.workflowStepColor)) ||
                    theme.palette.common.black
              }`
            : undefined,
          borderRightWidth:
            (selected || displayOptions?.miniature) && pageIsEven && nextPage
              ? 0
              : undefined,
          borderLeftWidth:
            selected && !pageIsEven && previousPage ? 0 : undefined,
          display: 'flex',
          height: pageWidth * (pageRatio || 1.1),
          width: '100%',
          cursor: onClick ? 'pointer' : undefined,
          position: 'relative',
        }}
      >
        {isPdf ? (
          <>
            <Box
              component="canvas"
              ref={canvasRef}
              sx={{
                height: '100%',
                width: '100%',
                opacity:
                  !displayOptions || displayOptions.displayThumbnails
                    ? undefined
                    : 0,
              }}
            />
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <CircularLoader size={pageWidth / 5} />
              </Box>
            )}
          </>
        ) : (
          <ImageLoader
            src={imageSrc}
            loaderSize={pageWidth / 5}
            sx={{
              height: '100%',
              width: '100%',
              opacity:
                !displayOptions || displayOptions.displayThumbnails
                  ? undefined
                  : 0,
            }}
          />
        )}
      </Box>
      {displayOptions?.displayPageNumber && (
        <Typography
          sx={{
            width: '100%',
            textAlign: pageIsEven ? 'left' : 'right',
            fontSize: 10,
            maxWidth: pageWidth,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: displayOptions?.miniature
              ? theme.palette.grey[500]
              : undefined,
            '@media print': {
              fontSize: 4,
              height: 6,
            },
          }}
        >
          {page.pageNumber}
        </Typography>
      )}
    </Box>
  );
};
