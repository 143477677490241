import { ButtonBase, Paper } from '@mui/material';
import { useDialog } from '@prismamedia/one-components';
import { FC, useState } from 'react';
import { FullscreenRailroad } from './Railroad/FullscreenRailroad';
import { RailroadIssueFullPage } from './Railroad/RailroadIssueFullPage';
import { HalfPage } from './Railroad/utils';
import { useGetRailRoadPages } from './utils';

export const Railroad: FC<{
  selectedIssue: string;
}> = ({ selectedIssue }) => {
  const { pages } = useGetRailRoadPages(selectedIssue);
  const { openDialog } = useDialog();
  const [pageRatio, setPageRatio] = useState<number | null>(null);

  if (!pages?.length) {
    return null;
  }

  const doublePages = pages?.reduce<(HalfPage | null)[][]>((prev, card) => {
    const lastBatch = prev[prev.length - 1];
    if (!lastBatch || lastBatch.length === 2 || prev.length === 1) {
      return [...prev, [card]];
    }
    // eslint-disable-next-line immutable/no-mutation
    prev[prev.length - 1] = [...lastBatch, card];
    return prev;
  }, []);

  return (
    <Paper sx={{ display: 'flex', overflowX: 'auto', p: 2, m: 2, mb: 0 }}>
      {doublePages?.map((doublePage, i) => (
        <ButtonBase
          key={i}
          sx={{ mr: 2 }}
          onClick={() => {
            openDialog(<FullscreenRailroad pages={pages} startingIndex={i} />, {
              fullScreen: true,
            });
          }}
        >
          <RailroadIssueFullPage
            pages={pages}
            doublePage={doublePage}
            pageWidth={100}
            pageRatio={pageRatio}
            setPageRatio={(ratio) => {
              setPageRatio((prev) => prev || ratio);
            }}
            displayOptions={{
              displayBorders: true,
              displayThumbnails: true,
              size: 0,
              miniature: false,
              displayInfos: false,
              displayPageNumber: true,
            }}
          />
        </ButtonBase>
      ))}
    </Paper>
  );
};
