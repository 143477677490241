import { Fullscreen, History, MenuBook, Print } from '@mui/icons-material';
import {
  AppBar,
  Box,
  createTheme,
  Divider,
  IconButton,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  HomeAppBar,
  IconLabelButton,
  PageWithDrawer,
  useAppBarHeight,
  useDialog,
  useOnMount,
} from '@prismamedia/one-components';
import { OneDrawerMenu } from 'components/OneDrawerMenu';
import { PrintPortal } from 'components/PrintPortal';
import { format } from 'date-fns';
import { FullscreenRailroad } from 'pages/IssuePage/Railroad/FullscreenRailroad';
import { RailroadIssueFullPage } from 'pages/IssuePage/Railroad/RailroadIssueFullPage';
import {
  HalfPage,
  MARGIN_BETWEEN_TWO_PAGES,
} from 'pages/IssuePage/Railroad/utils';
import { useGetRailRoadPages } from 'pages/IssuePage/utils';
import { FC, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { StringParam, useQueryParam } from 'use-query-params';
import { auth } from 'utils/auth';
import { DisplayOptions, Options } from './DisplayOptions';
import { FILTER_BAR_HEIGHT, FilterBar } from './FilterBar';
import { RailroadHistoryDialog } from './RailroadHistoryDialog';

export const RailroadPage: FC = () => {
  const appBarHeight = useAppBarHeight();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [selectedIssue, setSelectedIssue] = useState<string | undefined>(
    undefined,
  );
  const { openDialog } = useDialog();
  const [selectedIssueUrl, setSelectedIssueUrl] = useQueryParam(
    'issue',
    StringParam,
  );
  const [loadingUrlParams, setLoadingUrlParams] = useState(true);
  const [pageRatio, setPageRatio] = useState<number | null>(null);
  const [displayOptions, setDisplayOptions] = useState<Options>({
    displayBorders: true,
    displayThumbnails: true,
    size: 4,
    miniature: false,
    displayInfos: true,
    displayPageNumber: true,
  });

  const {
    pages,
    publicationDate,
    comment,
    censhareCreationDate,
    censhareUpdateDate,
    loading,
    error,
  } = useGetRailRoadPages(selectedIssue);

  useOnMount(() => {
    selectedIssueUrl && setSelectedIssue(selectedIssueUrl);
    setTimeout(() => setLoadingUrlParams(false));
  });

  useEffect(() => {
    setSelectedIssueUrl(selectedIssue, 'replaceIn');
  }, [selectedIssue, setSelectedIssueUrl]);

  const doublePages = pages?.reduce<(HalfPage | null)[][]>((prev, card) => {
    const lastBatch = prev[prev.length - 1];
    if (!lastBatch || lastBatch.length === 2 || prev.length === 1) {
      return [...prev, [card]];
    }
    // eslint-disable-next-line immutable/no-mutation
    prev[prev.length - 1] = [...lastBatch, card];
    return prev;
  }, []);

  const renderPage = (print?: boolean) => {
    if (!pages) return null;

    const halfPageWidth =
      ((((wrapperRef.current?.offsetWidth || window.innerWidth) - 2 * 8 - 1) /
        (9 - displayOptions.size) -
        2 * 8) /
        2) *
      (print ? 0.5 : 1);

    return (
      <Box
        ref={wrapperRef}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignSelf: 'center',
          pt: 2,
          pl: 2,
        }}
      >
        <Box
          sx={{
            width: halfPageWidth,
            color: theme.palette.grey[600],
            fontSize: 14,
            pr: 2,
            pb: 2,
            overflow: 'hidden',
          }}
        >
          {publicationDate && (
            <Box
              sx={{
                mb: 2,
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Box sx={{ fontWeight: 'bold' }}>Date de publication</Box>
              <Box>{format(new Date(publicationDate), 'P')}</Box>
            </Box>
          )}
          {comment && (
            <Box
              sx={{
                mb: 2,
                '@media print': {
                  display: 'none',
                },
              }}
            >
              <Box sx={{ fontWeight: 'bold' }}>Commentaire</Box>
              <Box sx={{ whiteSpace: 'pre-line' }}>{comment}</Box>
            </Box>
          )}
        </Box>
        {doublePages?.map((doublePage, i) => (
          <RailroadIssueFullPage
            key={i}
            pages={pages}
            doublePage={doublePage}
            pageWidth={halfPageWidth}
            displayOptions={displayOptions}
            sx={{
              mr: MARGIN_BETWEEN_TWO_PAGES,
              mb: 2,
              '@media print': {
                mb: 1,
              },
            }}
            pageRatio={pageRatio}
            setPageRatio={(ratio) => {
              setPageRatio((prev) => prev || ratio);
            }}
            onClick={() => {
              openDialog(
                <FullscreenRailroad pages={pages} startingIndex={i} />,
                {
                  fullScreen: true,
                },
              );
            }}
          />
        ))}
      </Box>
    );
  };

  return (
    <>
      <HomeAppBar
        appDrawerMenu={<OneDrawerMenu />}
        brandMenu={<Typography variant="h6">Chemin de fer</Typography>}
        currentUser={auth.user}
        disconnectUser={auth.logout}
        additionalElement={
          <ThemeProvider theme={createTheme({ palette: { mode: 'dark' } })}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {selectedIssue && (
                <>
                  <IconLabelButton
                    label="Imprimer"
                    onClick={() => window.print()}
                    sx={{ mr: 2 }}
                  >
                    <Print />
                  </IconLabelButton>
                  <IconLabelButton
                    label="Historique"
                    onClick={() => {
                      openDialog(
                        <RailroadHistoryDialog
                          censhareCreationDate={censhareCreationDate}
                          censhareUpdateDate={censhareUpdateDate}
                        />,
                      );
                    }}
                    edge="end"
                    size="large"
                  >
                    <History />
                  </IconLabelButton>
                </>
              )}
            </Box>
          </ThemeProvider>
        }
      />
      <AppBar
        position="fixed"
        color="default"
        sx={{
          top: appBarHeight,
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <FilterBar
          sx={{ mr: 'auto' }}
          selectedIssue={selectedIssue}
          setSelectedIssue={setSelectedIssue}
        />
        {pages && (
          <>
            <Tooltip title="Afficher en plein écran">
              <IconButton
                sx={{ color: theme.palette.primary.main }}
                onClick={() => {
                  openDialog(<FullscreenRailroad pages={pages} />, {
                    fullScreen: true,
                  });
                }}
              >
                <Fullscreen />
              </IconButton>
            </Tooltip>
            <Divider orientation="vertical" sx={{ height: 40, mx: 1 }} />
            <DisplayOptions
              sx={{ mr: 3 }}
              displayOptions={displayOptions}
              setDisplayOptions={setDisplayOptions}
            />
          </>
        )}
      </AppBar>
      <PageWithDrawer
        fullWidth
        sx={{ minHeight: '100%' }}
        paddingTop={FILTER_BAR_HEIGHT}
        error={error}
        loading={loading}
      >
        {selectedIssue ? (
          !loadingUrlParams &&
          (pages?.length ? (
            renderPage()
          ) : (
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                color: theme.palette.primary.main,
              }}
            >
              <Box sx={{ fontSize: 25 }}>
                Aucun chemin de fer pour cette parution
              </Box>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              color: theme.palette.primary.main,
            }}
          >
            <MenuBook sx={{ fontSize: 40, mb: 2 }} />
            <Box sx={{ fontSize: 25 }}>
              Sélectionnez un magazine et une parution
            </Box>
          </Box>
        )}
      </PageWithDrawer>
      <PrintPortal>{renderPage(true)}</PrintPortal>
    </>
  );
};
