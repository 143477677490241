import {
  HideImage,
  Image,
  InvertColors,
  InvertColorsOff,
  ZoomIn,
  ZoomOut,
} from '@mui/icons-material';
import {
  Box,
  Divider,
  IconButton,
  Slider,
  Stack,
  SxProps,
  Tooltip,
} from '@mui/material';
import { localStorage } from '@prismamedia/one-components';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { theme } from 'theme';

const LS_OPTIONS = 'LS_OPTIONS';

export interface Options {
  displayBorders: boolean;
  displayThumbnails: boolean;
  size: number;
  miniature: boolean;
  displayInfos: boolean;
  displayPageNumber: boolean;
}

export const DisplayOptions: FC<{
  displayOptions: Options;
  setDisplayOptions: Dispatch<SetStateAction<Options>>;
  sx?: SxProps;
}> = ({ displayOptions, setDisplayOptions, sx }) => {
  useEffect(() => {
    const lsOptions = localStorage.get<Options>(LS_OPTIONS);
    if (lsOptions) {
      setDisplayOptions((prev) => ({ ...prev, ...lsOptions }));
    }
  }, [setDisplayOptions]);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
      <Tooltip
        title={
          displayOptions.displayThumbnails
            ? 'Masquer les miniatures'
            : 'Afficher les miniatures'
        }
      >
        <IconButton
          onClick={() => {
            localStorage.set(LS_OPTIONS, {
              ...displayOptions,
              displayThumbnails: !displayOptions.displayThumbnails,
            });
            setDisplayOptions((prev) => ({
              ...prev,
              displayThumbnails: !prev.displayThumbnails,
            }));
          }}
          sx={{
            color: displayOptions.displayThumbnails
              ? theme.palette.primary.main
              : theme.palette.grey[400],
          }}
        >
          {displayOptions.displayThumbnails ? <Image /> : <HideImage />}
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          displayOptions.displayBorders
            ? 'Masquer les bordures'
            : 'Afficher les bordures'
        }
      >
        <IconButton
          onClick={() => {
            localStorage.set(LS_OPTIONS, {
              ...displayOptions,
              displayBorders: !displayOptions.displayBorders,
            });
            setDisplayOptions((prev) => ({
              ...prev,
              displayBorders: !prev.displayBorders,
            }));
          }}
          sx={{
            color: displayOptions.displayBorders
              ? theme.palette.primary.main
              : theme.palette.grey[400],
          }}
        >
          {displayOptions.displayBorders ? (
            <InvertColors />
          ) : (
            <InvertColorsOff />
          )}
        </IconButton>
      </Tooltip>
      <Divider orientation="vertical" sx={{ height: 40, mx: 1 }} />
      <Stack spacing={2} direction="row" sx={{ alignItems: 'center', ml: 1 }}>
        <ZoomOut />
        <Slider
          value={displayOptions.size}
          onChange={(_, value) => {
            localStorage.set(LS_OPTIONS, {
              ...displayOptions,
              size: value as number,
            });
            setDisplayOptions((prev) => ({
              ...prev,
              size: value as number,
            }));
          }}
          sx={{ width: 100 }}
          max={8}
          min={1}
          step={1}
        />
        <ZoomIn />
      </Stack>
    </Box>
  );
};
