import { ArrowBackIosNew, ArrowForwardIos, Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { useDialog } from '@prismamedia/one-components';
import { FC, useEffect, useRef, useState } from 'react';
import { theme } from 'theme';
import { FullscreenRailroadMiniature } from './FullscreenRailroadMiniature';
import { RailroadIssueFullPage } from './RailroadIssueFullPage';
import { HalfPage } from './utils';

export const FullscreenRailroad: FC<{
  pages: HalfPage[];
  startingIndex?: number;
}> = ({ pages, startingIndex }) => {
  const { closeDialog } = useDialog();
  const [pageRatio, setPageRatio] = useState<number | null>(null);
  const [selectedPage, setSelectedPage] = useState(startingIndex || 0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const doublePages = pages?.reduce<(HalfPage | null)[][]>((prev, card) => {
    const lastBatch = prev[prev.length - 1];
    if (!lastBatch || lastBatch.length === 2 || prev.length === 1) {
      return [...prev, [card]];
    }
    // eslint-disable-next-line immutable/no-mutation
    prev[prev.length - 1] = [...lastBatch, card];
    return prev;
  }, []);

  useEffect(() => {
    if (!wrapperRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      setWidth(wrapperRef.current?.offsetWidth || 0);
      setHeight(wrapperRef.current?.offsetHeight || 0);
    });
    resizeObserver.observe(wrapperRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        bgcolor: theme.palette.grey[800],
      }}
    >
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
        }}
        ref={wrapperRef}
      >
        <RailroadIssueFullPage
          pages={pages}
          doublePage={doublePages[selectedPage]}
          pageWidth={Math.min(width / 2, height / (pageRatio || 1.1)) - 50}
          pageRatio={pageRatio}
          setPageRatio={(ratio) => {
            setPageRatio((prev) => prev || ratio);
          }}
          displayOptions={{
            displayBorders: false,
            displayThumbnails: true,
            size: 0,
            miniature: false,
            displayInfos: false,
            displayPageNumber: false,
          }}
        />
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            left: 8,
            color: theme.palette.common.white,
          }}
          disabled={selectedPage === 0}
          onClick={() => setSelectedPage((prev) => prev - 1)}
        >
          <ArrowBackIosNew />
        </IconButton>
        <IconButton
          sx={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: 8,
            color: theme.palette.common.white,
          }}
          disabled={selectedPage === doublePages.length - 1}
          onClick={() => setSelectedPage((prev) => prev + 1)}
        >
          <ArrowForwardIos />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          p: 1,
          flexShrink: 0,
          bgcolor: theme.palette.common.white,
        }}
      >
        {doublePages?.map((doublePage, i) => (
          <FullscreenRailroadMiniature
            key={i}
            pages={pages}
            doublePage={doublePage}
            pageRatio={pageRatio}
            setPageRatio={(ratio) => {
              setPageRatio((prev) => prev || ratio);
            }}
            selected={selectedPage === i}
            onSelect={() => setSelectedPage(i)}
          />
        ))}
      </Box>
      <IconButton
        sx={{
          position: 'absolute',
          top: 8,
          right: 8,
          color: theme.palette.common.white,
        }}
        onClick={() => closeDialog()}
      >
        <Close />
      </IconButton>
    </Box>
  );
};
