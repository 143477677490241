import { ButtonBase, SxProps } from '@mui/material';
import { FC, useEffect, useRef } from 'react';
import { RailroadIssueFullPage } from './RailroadIssueFullPage';
import { HalfPage } from './utils';

export const FullscreenRailroadMiniature: FC<{
  pages: (HalfPage | null)[];
  doublePage: (HalfPage | null)[];
  sx?: SxProps;
  pageRatio: number | null;
  setPageRatio: (ratio: number | null) => void;
  selected?: boolean;
  onSelect: () => void;
}> = ({
  pages,
  doublePage,
  sx,
  setPageRatio,
  pageRatio,
  selected,
  onSelect,
}) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (selected) {
      ref.current?.scrollIntoView({ behavior: 'smooth', inline: 'center' });
    }
  }, [selected]);

  return (
    <ButtonBase
      ref={ref}
      onClick={() => onSelect()}
      sx={{
        mr: 2,
        ...sx,
      }}
    >
      <RailroadIssueFullPage
        pages={pages}
        doublePage={doublePage}
        pageWidth={50}
        pageRatio={pageRatio}
        setPageRatio={setPageRatio}
        selected={selected}
        displayOptions={{
          displayBorders: true,
          displayThumbnails: true,
          size: 0,
          miniature: true,
          displayInfos: false,
          displayPageNumber: true,
        }}
      />
    </ButtonBase>
  );
};
